import {
    LOGOUT,
    SET_ALL_PRODUCTS_LOADING,
    SET_ALL_PRODUCTS,
    SET_ADD_PRODUCT_LOADING,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CLEAR_CART,
} from "../actions/types";
import { uniqueById } from "../utils/unique";

const initialState = {
    all: {
        loading: false,
        list: [],
    },
    cart: {
        items: [],
    },
    addProductLoading: false,
};

const persistCart = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
}

const initialCart = () => {
    try {
        const cart = JSON.parse(localStorage.getItem("cart"));
        return cart || initialState.cart;
    } catch (err) {
        console.error(err);
        return initialState.cart;
    }
};

const products = (state = { ...initialState, cart: initialCart() }, action) => {
    switch (action.type) {
        case SET_ALL_PRODUCTS_LOADING: {
            return { ...state, all: { ...state.all, loading: action.payload } };
        }
        case SET_ALL_PRODUCTS: {
            return { ...state, all: { ...state.all, list: action.payload } };
        }
        case SET_ADD_PRODUCT_LOADING: {
            return { ...state, addProductLoading: action.payload };
        }
        case ADD_TO_CART: {
            const newCart = {
                ...state.cart,
                items: uniqueById([ ...state.cart.items, { ...action.payload, qty: 1 } ])
            };
            persistCart(newCart);
            return {
                ...state,
                cart: newCart
            };
        }
        case REMOVE_FROM_CART: {
            const newCart = {
                ...state.cart,
                items: state.cart.items.filter((item) => item.id !== action.payload)
            };
            persistCart(newCart);
            return {
                ...state,
                cart: newCart
            };
        };
        case CLEAR_CART: {
            persistCart(initialState.cart);
            return { ...state, cart: { ...initialState.cart } };
        }
        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default products;
