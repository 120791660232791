// UI
export const APP_LOADING                               = "APP_LOADING";
export const SET_ACTIVE_NAV_TAB                        = "SET_ACTIVE_NAV_TAB";
export const SET_LOGIN_MODAL_OPEN                      = "SET_LOGIN_MODAL_OPEN";
export const SET_RESET_PASSWORD_MODAL_OPEN             = "SET_RESET_PASSWORD_MODAL_OPEN";

// ERRORS
export const SET_ERRORS                = "SET_ERRORS";
export const CLEAR_ERRORS              = "CLEAR_ERRORS";
export const SET_BUG_REPORT_MODAL_OPEN = "SET_BUG_REPORT_MODAL_OPEN";
export const BUG_REPORT_LOADING        = "BUG_REPORT_LOADING";

// USER
export const SET_USER                       = "SET_USER";
export const USER_LOADING                   = "USER_LOADING";
export const RESET_PASSWORD_LOADING         = "RESET_PASSWORD_LOADING";
export const LOGOUT                         = "LOGOUT";

// PRODUCTS
export const SET_ALL_PRODUCTS_LOADING  = "SET_ALL_PRODUCTS_LOADING";
export const SET_ALL_PRODUCTS          = "SET_ALL_PRODUCTS";
export const ADD_TO_CART               = "ADD_TO_CART";
export const REMOVE_FROM_CART          = "REMOVE_FROM_CART";
export const CLEAR_CART                = "CLEAR_CART";
export const SET_ADD_PRODUCT_LOADING       = "SET_ADD_PRODUCT_LOADING";
