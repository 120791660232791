import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Message } from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";

import CartTotal from "../components/CartTotal";

const Checkout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cart = useSelector((state) => state.products.cart.items);
    const cartLoading = useSelector((state) => state.products.cart.loading);
    const total = 0.00; // TODO(adam): Add billing
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.PAYMENT));

    useEffect(() => {
        if (!cartLoading && cart.items.length === 0) {
            history.push("/store");
        }
    }, [cartLoading, cart.items]);

    const renderContent = () => {
        if (cartLoading) {
            return (
                <Card.Content>
                    Loading...
                </Card.Content>
            );
        }
        return (
            <Card.Content>
                <CartTotal
                    total={total}
                />
            </Card.Content>
        );
    };

    const handlePayment = async () => {
    };

    return (
        <Card className="dashboard-card">
            {renderContent()}
            <Card.Content>
                <div id="card-container"></div>
                <Button
                    onClick={handlePayment}
                    primary
                    fluid
                    disabled={cartLoading}
                >
                    Pay
                </Button>
                {errors && (
                    <Message
                        error
                        header="Payment Error"
                        content={errors[0].message}
                    />
                )}
            </Card.Content>
        </Card>
    );
};

export default Checkout;
