import React from "react";
import { useSelector } from "react-redux";
import { Grid, Card } from "semantic-ui-react";
import LoginForm from "../components/LoginForm";

const render = () => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Card className="dashboard-card" centered>
                    <Card.Content>
                        <Card.Header>Welcome</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        Welcome to the SpaceCRAFT console. You can go to the Downloads page
                        to download applications that you've already purchased, or you can
                        go the Store to add more products to your account.
                    </Card.Content>
                </Card>
            </Grid.Column>
        </Grid.Row>
    );
};

const Home = () => {
    const user = useSelector((state) => state.user);

    return (
        <Grid columns={1} stackable>
            {!user.loggedIn && (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90vw",
                        background: "rgba(5, 5, 5, 0.5)"
                    }}
                >
                    <LoginForm />
                </div>
            )}
            {user.loggedIn && render()}
        </Grid>
    );
}

export default Home;
