import {
    SET_ERRORS,
    CLEAR_ERRORS,
} from "./types";
import { ErrorTag } from "../utils/enums";
import { getToken } from "./user";
import authServer from "../api/auth";

const getError = err => {
    err.tag = err.tag || "Unknown";
    // Handle axios response errors
    if (err.response) {
        err.status = err.response.status || 500;
        err.message =
            (err.response.data && err.response.data.message) || "Unknown error";
        err.field = err.response.data && err.response.data.field;
        err.link = err.response.data && err.response.data.link;
    } else {
        err.status = 500;
        err.message = err.message || "Unknown error";
    }

    return {
        tag: err.tag,
        status: err.status,
        message: err.message,
        field: err.field,
        link: err.link
    };
};

export const addError = err => {
    return {
        type: SET_ERRORS,
        payload: [getError(err)]
    }
};

export const setErrors = errs => {
    if (!Array.isArray(errs)) {
        errs = [ errs ];
    }
    return {
        type: SET_ERRORS,
        payload: errs
    };
};

export const clearErrors = tag => ({ type: CLEAR_ERRORS, payload: tag });
