import {
    APP_LOADING,
    SET_ACTIVE_NAV_TAB,
    SET_LOGIN_MODAL_OPEN,
    SET_RESET_PASSWORD_MODAL_OPEN,
} from "./types";
import { ErrorTag } from "../utils/enums";
import { clearErrors } from "./errors";

export const setActiveNavTab = tab => {
    if (tab)
        localStorage.setItem("navTab", tab);
    else
        localStorage.removeItem("navTab");
    return {
        type: SET_ACTIVE_NAV_TAB,
        payload: tab
    };
};

export const openLoginModal = () => dispatch => {
    dispatch(clearErrors(ErrorTag.LOGIN));
    dispatch({
        type: SET_LOGIN_MODAL_OPEN,
        payload: true
    });
};

export const closeLoginModal = () => ({
    type: SET_LOGIN_MODAL_OPEN,
    payload: false
});

export const setAppLoading = isLoading => ({
    type: APP_LOADING,
    payload: isLoading
});

export const openResetPasswordModal = () => ({
    type: SET_RESET_PASSWORD_MODAL_OPEN,
    payload: true
});

export const closeResetPasswordModal = () => ({
    type: SET_RESET_PASSWORD_MODAL_OPEN,
    payload: false
});

