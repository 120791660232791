import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";
import { setActiveNavTab } from "../actions/ui";
import { removeFromCart } from "../actions/products";
import { addProduct } from "../actions/user";

import CartList from "../components/CartList";
import CartTotal from "../components/CartTotal";

const Cart = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector((state) => state.products.all.loading);
    const addProductLoading = useSelector((state) => state.products.addProductLoading);
    const cart = useSelector((state) => state.products.cart);
    const total = 0.00; // TODO(adam): Add billing

    useEffect(() => {
        dispatch(setActiveNavTab(null));
    }, []);

    const handleRemoveItem = (id) => {
        dispatch(removeFromCart(id));
    };

    const handleCheckout = () => {
        cart.items.forEach(p => dispatch(addProduct(p.id)));
        history.push("/downloads");
    };

    const contentStyle = {
        padding: "1rem 2.5rem"
    };
    const renderContent = () => {
        if (loading) {
            return (
                <Card.Content>
                    Loading...
                </Card.Content>
            );
        }
        if (cart.items.length === 0) {
            return (
                <React.Fragment>
                    <Card.Content>
                        <p>You don't have any items in your cart.</p>
                        <Button
                            primary
                            onClick={() => history.push("/store")}
                        >
                            Go To Store
                        </Button>
                    </Card.Content>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Card.Content style={contentStyle}>
                    <CartList cart={cart} onRemoveItem={handleRemoveItem} />
                </Card.Content>
                <Card.Content style={contentStyle}>
                    <CartTotal
                        total={total}
                    />
                </Card.Content>
                <Card.Content>
                    <Button
                        onClick={handleCheckout}
                        fluid
                        primary
                        disabled={cart.items.length === 0}
                        loading={addProductLoading}
                    >
                        Checkout
                    </Button>
                </Card.Content>
            </React.Fragment>
        );
    }

    return (
        <Card className="dashboard-card">
            <Card.Content>
                <Card.Header>Cart</Card.Header>
            </Card.Content>
            {renderContent()}
        </Card>
    );
};

export default Cart;
