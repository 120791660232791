const urlBase = process.env.REACT_APP_URL_BASE;

let authUrl;
if (process.env.NODE_ENV === "development") {
    authUrl = "http://localhost:5000";
} else {
    authUrl = `https://${urlBase}`;
}
export const AUTH_URL = authUrl;

let usersUrl;
if (process.env.NODE_ENV === "development") {
    usersUrl = "http://localhost:5000/users";
} else {
    usersUrl = `https://${urlBase}/users`;
}
export const USERS_URL = usersUrl;

let productsUrl;
if (process.env.NODE_ENV === "development") {
    productsUrl = "http://localhost:5000/products";
} else {
    productsUrl = `https://${urlBase}/products`;
}
export const PRODUCTS_URL = productsUrl;
