import {
    SET_ALL_PRODUCTS_LOADING,
    SET_ALL_PRODUCTS,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CLEAR_CART,
} from "./types";
import { addError, clearErrors } from "./errors";
import { ErrorTag } from "../utils/enums";
import productsServer from "../api/products";
import { getToken } from "./user";

const _getAllProducts = async token => {
    try {
        const productsResult = await productsServer.get(
            "/all", { headers: { Authorization: token } }
        );
        return productsResult.data;
    } catch (err) {
        throw err;
    }
};

export const getAllProducts = () => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.PRODUCTS));
        dispatch({ type: SET_ALL_PRODUCTS_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        const products = await _getAllProducts(token);
        dispatch({
            type: SET_ALL_PRODUCTS,
            payload: products,
        });

    } catch (err) {
        err.tag = ErrorTag.PRODUCTS;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_ALL_PRODUCTS_LOADING, payload: false });
    }
};

export const addToCart = (product) => ({
    type: ADD_TO_CART,
    payload: product
});

export const removeFromCart = (id) => ({
    type: REMOVE_FROM_CART,
    payload: id
});

export const clearCart = () => ({
    type: CLEAR_CART,
});

