/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavTab } from "../utils/enums";
import { setActiveNavTab } from "../actions/ui";
import {
    Container,
    Card,
    Item,
    Message,
} from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import ProductItem from "../components/ProductItem";

const Downloads = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const loading = useSelector((state) => state.user.loading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.PRODUCTS));

    React.useEffect(() => {
        dispatch(setActiveNavTab(NavTab.DOWNLOADS));
    }, []);

    const renderContent = () => {
        if (loading) {
            return "Loading...";
        }
        if (user && user.products && user.products.length === 0) {
            return "You haven't purchased any items yet. To purchase an application, " +
                "go to the Store";
        }
        return (
            <Item.Group divided>
                {user.products.map(p => <ProductItem product={p} />)}
            </Item.Group>
        );
    };

    return (
        <Container>
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Card.Header>Application Download</Card.Header>
                </Card.Content>
                <Card.Content>
                    <div style={{ padding: "1rem" }}>
                        {renderContent()}
                    </div>
                    {errors && errors.length && (
                        <Message
                            error
                            header="Error Getting Download Link"
                            content={errors[0].message}
                        />
                    )}
                </Card.Content>
            </Card>
        </Container>
    );
};

export default Downloads;
