/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
    Item,
    Button,
} from "semantic-ui-react";

const ProductItem = ({ product }) => {
    return (
        <Item>
            <Item.Content>
                <Item.Header>{product.name}</Item.Header>
                <Item.Meta>{`Version: ${product.version}`}</Item.Meta>
                <Item.Extra>
                    <Button
                        primary
                        as="a"
                        href={product.url}
                    >
                        Download
                    </Button>
                </Item.Extra>
            </Item.Content>
        </Item>
    );
};

export default ProductItem;
