export const NavTab = {
    HOME: "Home",
    SIMULATIONS: "Simulations",
    COMPETITIONS: "Competitions",
    TEAMS: "Teams",
    LEADERBOARD: "Leaderboard",
    CHAT: "Chat",
    DOWNLOADS: "Downloads",
    USER_MANAGEMENT: "Users",
    TEAM_MANAGEMENT: "Teams",
    HELP: "Help",
    STORE: "Store"
};

export const ErrorTag = {
    USER: "user",
    LOGIN: "login",
    SIGNUP: "signup",
    RESET_PASSWORD: "resetPassword",
    PRODUCTS: "products",
    ADD_PRODUCT: "addProduct",
};
export const UserType = {
    USER: "USER",
    INITIAL_USER: "INITIAL_USER",
    MENTOR: "MENTOR",
    ADMIN: "ADMIN",
    ORG_MANAGER: "ORG_MANAGER",
};
