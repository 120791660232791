import {
    SET_USER,
    USER_LOADING,
    RESET_PASSWORD_LOADING,
    LOGOUT
} from "../actions/types";

const initialState = {
    loading: false,
    loggedIn: false,
    resetPasswordLoading: false,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER: {
            return { ...state, ...action.payload, loading: false };
        }
        case USER_LOADING: {
            return { ...state, loading: action.payload };
        }
        case RESET_PASSWORD_LOADING: {
            return {
                ...state,
                resetPasswordLoading: action.payload
            };
        }
        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default user;
