import {
    APP_LOADING,
    SET_ACTIVE_NAV_TAB,
    SET_LOGIN_MODAL_OPEN,
    SET_RESET_PASSWORD_MODAL_OPEN,
} from "../actions/types";

const initialState = {
    loading: true,
    activeNavTab: localStorage.getItem("navTab") || "Home",
    loginModalOpen: false,
    resetPasswordModalOpen: false,
};

const ui = (state = initialState, action) => {
    switch (action.type) {
        case APP_LOADING: {
            return { ...state, loading: action.payload };
        }
        case SET_ACTIVE_NAV_TAB: {
            return { ...state, activeNavTab: action.payload };
        }
        case SET_LOGIN_MODAL_OPEN: {
            return { ...state, loginModalOpen: action.payload };
        }
        case SET_RESET_PASSWORD_MODAL_OPEN: {
            return { ...state, resetPasswordModalOpen: action.payload };
        }
        default: {
            return state;
        }
    }
};

export default ui;
