import {
    SET_USER,
    USER_LOADING,
    SET_ACTIVE_NAV_TAB,
    SET_ADD_PRODUCT_LOADING,
    LOGOUT
} from "./types";
import { addError, clearErrors } from "./errors";
import { closeLoginModal, setAppLoading } from "./ui";
import { clearCart } from "./products";
import { ErrorTag } from "../utils/enums";
import authServer from "../api/auth";
import userServer from "../api/users";
import store from "../store";

export const getToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
        store.dispatch(logout());
        store.dispatch({ type: SET_ACTIVE_NAV_TAB, payload: "Home" });
        return null;
    }
    return token;
};

const getUserData = async token => {
    try {
        const userResult = await userServer.get(
            "/", { headers: { Authorization: token } }
        );
        return userResult.data;
    } catch (err) {
        throw err;
    }
};

const _addProduct = async (productId, token) => {
    try {
        await userServer.post(
            "/products",
            { productId },
            { headers: { Authorization: token } },
        );
    } catch (err) {
        throw err;
    }
};

export const signup = (userData) => async dispatch => {
    if (userData.password !== userData.confirmPassword) {
        dispatch(addError({
            tag: ErrorTag.SIGNUP,
            field: "confirmPassword",
            message: "Passwords do not match"
        }));
        return;
    }

    dispatch(clearErrors(ErrorTag.SIGNUP));
    dispatch({ type: USER_LOADING, payload: true });

    try {
        await userServer.post("/", { ...userData });
        dispatch(login(userData.username, userData.password));
    } catch (err) {
        err.tag = ErrorTag.SIGNUP;
        dispatch(addError(err));
    } finally {
        dispatch({ type: USER_LOADING, payload: false });
    }
};

// TODO(adam): Set auth header globally
export const login = (usernameOrEmail, password) => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.LOGIN));
        dispatch({ type: USER_LOADING, payload: true });

        // Get token
        const email_exp = /^[^\s;@]+@[^\s;@]+\.[^\s;@]+$/;
        let loginResult;
        if (email_exp.test(usernameOrEmail)) {
            loginResult = await authServer.post("/login", { email: usernameOrEmail, password });
        } else {
            loginResult = await authServer.post("/login", { username: usernameOrEmail, password });
        }  
        const token = loginResult.data;
        localStorage.setItem("token", token);

        // Get user info
        try {
            const user = await getUserData(token);
            dispatch(clearErrors(ErrorTag.USER));
            dispatch({
                type: SET_USER,
                payload: { ...user, loggedIn: true }
            });
            dispatch(closeLoginModal());
            dispatch(loadUserData());

        } catch (err) {
            err.tag = ErrorTag.USER;
            dispatch(addError(err));
        }
    } catch (err) {
        err.tag = ErrorTag.LOGIN;
        dispatch(addError(err));
    } finally {
        dispatch({ type: USER_LOADING, payload: false });
    }
};

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("navTab");
    return {
        type: LOGOUT
    };
};

export const loadUserData = () => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.USER));
        dispatch({ type: USER_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        const user = await getUserData(token);
        dispatch({
            type: SET_USER,
            payload: { ...user, loggedIn: true }
        });

    } catch (err) {
        err.tag = ErrorTag.USER;
        dispatch(addError(err));
        logout();
    } finally {
        dispatch({ type: USER_LOADING, payload: false });
        dispatch(setAppLoading(false));
    }
};

export const addProduct = (productId) => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.ADD_PRODUCT));
        dispatch({ type: SET_ADD_PRODUCT_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _addProduct(productId, token);
        dispatch(loadUserData());
        dispatch(clearCart());

    } catch (err) {
        err.tag = ErrorTag.ADD_PRODUCT;
        dispatch(addError(err));
        logout();
    } finally {
        dispatch({ type: SET_ADD_PRODUCT_LOADING, payload: false });
    }
};
