import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { setActiveNavTab } from "../actions/ui";
import { NavTab } from "../utils/enums";
import { addToCart, getAllProducts, removeFromCart } from "../actions/products";
import {
    Button,
    Card
} from "semantic-ui-react";

import StoreList from "../components/StoreList";

const Store = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector((state) => state.products.all.loading);
    const products = useSelector((state) => state.products.all.list);
    const userProducts = useSelector((state) => state.user.products);
    const cart = useSelector((state) => state.products.cart.items);

    const isSelected = (product) => {
        return cart.some((item) => item.id === product.id);
    };

    const handleAddToCart = (product) => {
        dispatch(addToCart(product));
    };

    const handleRemoveFromCart = (id) => {
        dispatch(removeFromCart(id));
    };

    useEffect(() => {
        dispatch(setActiveNavTab(NavTab.STORE));
        dispatch(getAllProducts());
    }, []);

    const renderContent = () => {
        if (loading) {
            return (
                <p>
                    Loading...
                </p>
            );
        }
        return (
            <StoreList
                items={products.filter(p => !userProducts.some(up => up.id === p.id)).map((product) => {
                    return {
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        selected: isSelected(product)
                    };
                })}
                onAddToCart={handleAddToCart}
                onRemoveFromCart={handleRemoveFromCart}
            />
        );
    };

    return (
        <Card className="dashboard-card" centered>
            <Card.Content>
                <Card.Header>Products</Card.Header>
            </Card.Content>
            <Card.Content>
                {renderContent()}
            </Card.Content>
            <Card.Content>
                <Button
                    onClick={() => history.push("/cart")}
                    primary
                    fluid
                >
                    Cart
                </Button>
            </Card.Content>
        </Card>
    );
}

export default Store;
