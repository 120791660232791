import { combineReducers } from "redux";
import ui from "./ui";
import user from "./user";
import errors from "./errors";
import products from "./products";

export default combineReducers({
    ui,
    user,
    errors,
    products,
});
