/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { NavTab } from "./utils/enums";
import "./App.scss";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

import LoginModal from "./components/LoginModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import Downloads from "./pages/Downloads";
import Store from "./pages/Store";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";

import {
    openLoginModal,
    closeLoginModal,
    setAppLoading,
    openResetPasswordModal,
    closeResetPasswordModal,
    setActiveNavTab,
} from "./actions/ui";
import { loadUserData } from "./actions/user";
import { Loader } from "semantic-ui-react";

const App = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);

    React.useEffect(() => {
        if (localStorage.getItem("token"))
            dispatch(setAppLoading(true));
        dispatch(loadUserData());
    }, []);

    const renderDashboardContent = (tab) => {
        switch (tab) {
        case NavTab.HOME: {
            return <Home />;
        }
        case NavTab.DOWNLOADS: {
            history.push("/downloads");
            return <div />;
        }
        case NavTab.STORE: {
            history.push("/store");
            return <div />;
        }
        default: {
            setActiveNavTab(NavTab.HOME);
            return <Home />;
        }
        }
    };

    const navHeight = plus => `${6 + plus}rem`;

    const renderApp = () => {
        const loginBlockedPaths = [
            "/signup",
        ];

        if (user.loggedIn && loginBlockedPaths.some(p => location.pathname.startsWith(p))) {
            history.push("/");
        }

        if (ui.loading) {
            return (
                <Loader active />
            );
        }
        if (!user.loggedIn) {
            const blockedPaths = [
                "/downloads",
            ];
            if (blockedPaths.some(p => location.pathname.startsWith(p))) {
                history.push("/");
            }
            return (
                <>
                    <div style={{ paddingTop: navHeight(4) }}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/signup" component={Signup} />
                            <Route exact path="/reset-password/:key" component={ResetPassword} />
                        </Switch>
                        <ResetPasswordModal
                            open={ui.resetPasswordModalOpen}
                            onOpen={() => dispatch(openResetPasswordModal())}
                            onClose={() => dispatch(closeResetPasswordModal())}
                        />
                        <LoginModal
                            open={ui.loginModalOpen}
                            onOpen={() => dispatch(openLoginModal())}
                            onClose={() => dispatch(closeLoginModal())}
                        />
                    </div>
                    <Navbar height={navHeight} />
                </>
            );
        }
        return ( // Regular user logged in
            <>
                <div style={{ paddingTop: navHeight(4) }}>
                    <Switch>
                        <Route exact path="/" component={() => renderDashboardContent(ui.activeNavTab)} />
                        <Route exact path="/downloads" component={Downloads} />
                        <Route exact path="/store" component={Store} />
                        <Route exact path="/cart" component={Cart} />
                        <Route exact path="/checkout" component={Checkout} />
                    </Switch>
                    <LoginModal
                        open={ui.loginModalOpen}
                        onOpen={() => dispatch(openLoginModal())}
                        onClose={() => dispatch(closeLoginModal())}
                    />
                </div>
                <Navbar height={navHeight} />
            </>
        );
    };

    return (
        <div className="App">
            {renderApp()}
        </div>
    );
};

export default App;
